body {
  margin: 0;
 
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


.disabled-btn{
  opacity: 0.4;
  cursor: not-allowed !important;
}